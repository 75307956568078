<template>
  <div class="p-0 m-0 contact-wrapper">
      <div class="left-bottom-img-wrapper p-0 m-0">
          <img class="left-bottom-bg" src="../assets/Login/loginLeftBG.png" />
          <img class="contact-left-bg-img" src="../assets/contact-us/leftBottomImg.svg" />
      </div>
      <div class="right-bottom-img-wrapper p-0 m-0">
          <img class="right-top-bg" src="../assets/Login/loginRightBG.png" />
          <img class="login-right-bg-img" src="../assets/contact-us/rightTopImg.svg" />
      </div>
      <div class="row m-0 contact-header">
          <div class="col-6 p-0 m-0 hecto-logo">
              <span @click="gotoLandingPage">
                  <img src="../assets/nav/HectocommerceLogo.svg">
              </span>
          </div>
          <div class="col-6 ml-auto text-left request-demo-btn">
            <w-button-input 
                :buttonClass="'request-demo'"
                :buttonStyle="'request-demo-style'"
                :label="'Request Demo'"
                @buttonClicked="requestDemoClick($event)"
            />
          </div>
          <div class="col-12 p-0 m-0 text-left back-btn">
            <span @click="gotoLandingPage">
                 <img src="../assets/Login/rightArrow.svg"> BACK
            </span>
          </div>
      </div>
      <div class="row p-0 m-0">
          <div class="col-12 text-center contact-title">
              Need to get in touch ?
          </div>
          <div class="col-lg-6 col-md-6 col-2 mb-3 text-right">
              <img class="detail-icon" src="../assets/contact-us/phone.svg" />
          </div>
          <div class="col-lg-6 col-md-6 col-9 mb-3 text-left contact-details">
              9845537400
          </div>
          <div class="col-lg-6 col-md-6 col-2 mb-3 text-right">
              <img class="detail-icon" src="../assets/contact-us/mail.svg" />
          </div>
          <div class="col-lg-6 col-md-6 col-9 mb-3 text-left contact-details">
              info@diatoz.com
          </div>
          <div class="col-lg-6 col-md-6 col-2 mb-3 text-right">
              <img class="detail-icon" src="../assets/contact-us/location.svg" />
          </div>
          <div class="col-lg-3 col-md-4 col-10 text-left contact-details">
            <!-- <pre class="text-left"> -->
                DIATOZ Solutions Private Limited, 
                2nd floor, VMR Complex, #306, 
                Above Axis Bank, Varthur Rd, 
                BEML Layout, Brookefield, 
                Bengaluru, KA 560066.
            <!-- </pre>  -->
          </div>
          <div class="col-12">
              <div>
                <!-- <w-button-input 
                    :buttonClass="'request-demo2'"
                    :buttonStyle="'request-demo-style'"
                    :label="'Request Demo'"
                    @buttonClicked="requestDemoClick($event)"
                /> -->
              </div>
          </div>
      </div>
      <div class="row p-0 m-0">
          <div class="col-lg-8 col-md-6 col-12 ml-auto">
              <!-- <GoogleMap/> -->
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.244871433495!2d77.71431121482183!3d12.956176690866037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae13721c3365ff%3A0x230e8b5e4785426f!2sDiatoz%20Solutions%20Private%20Limited!5e0!3m2!1sen!2sin!4v1619014140692!5m2!1sen!2sin" 
                width="100%" 
                height="100%" 
                style="border-radius:5px; border:0;" 
                allowfullscreen="" 
                loading="lazy"
            ></iframe>
          </div>
      </div>
    <w-modal
        :trigger="alert"
        :centered="true"
        :dontCloseWhenClickedOutside="true"
        :dontCloseWhenEscapePressed="true"
        :dontShowCloseIcon="true"
        :modalbuttonStyle="true"
        :alert="alert"
        :alertMsg="alertMsg"
        :alertType="alertType"
        @eventClose="closeModal()"
    />
  </div>
</template>

<script>
import ButtonInput from "../widgets/InputButton.vue";
import Modal from "../widgets/ModalWidget.vue";
import GoogleMap from "../widgets/GoogleMaps.vue";

export default {
    components:{
        "w-button-input": ButtonInput,
        "w-modal": Modal,
        GoogleMap
    },
    data() {
        return {
            alertType: "",
            alert: false,
            alertMsg: "",
            isConfirmAlert: false,
        };
    },
    methods:{
        requestDemoClick(){
            this.$router.push("/pre-register")
        },
        gotoLandingPage(){
            this.$router.push('/')
        },
    }
}
</script>

<style scoped>
    .back-btn{
        font-size: 18px;
        font-family: "bebas-kai";
        color: #A3A3A3;
    }
    .back-btn span{
        cursor: pointer;
    }
    /* .contact-wrapper{
        overflow-y: hidden;
    } */
    .left-bottom-img-wrapper{
        position: absolute;
        bottom: 0px;
    }
    .left-bottom-img-wrapper > .left-bottom-bg{
        width:30rem;
    }
    .left-bottom-img-wrapper > .contact-left-bg-img{
        position: absolute;
        top: 30%;
        left: 18%;
        width: 80%;
    }
    .right-bottom-img-wrapper{
        position: absolute;
        top: 0;
        right: 0px;
    }
    .right-bottom-img-wrapper > .right-top-bg{
        width:30rem;
    }
    .right-bottom-img-wrapper > .login-right-bg-img{
        position: absolute;
        top: 25%;
        left: 22%;
    }
    .contact-wrapper{
        background-color: #fff;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
    }
    .contact-wrapper::-webkit-scrollbar {
        display: none;
    }
    .contact-header{
        padding: 1rem 6.1rem;
    }
    .contact-header > .hecto-logo{
        font-family: "bebas-kai";
        font-size: 2rem;
        color:#464646;
    }
    .hecto-logo span{
        cursor: pointer;
    }
    .login-form{
        height: 80%;
    }
    .login-form-wrapper{
        padding:1rem 2rem;
    }
    .login-title{
        font-family: "bebas-kai";
        font-size: 3rem;
        color:#464646;
    }
    .forget-password, .click-here{
        color:#464646;
        font-family: "proxima-nova", sans-serif;
        font-size: 12px;
        font-weight: bold;
        margin-top: 20px;
    }
    .forget-password > .click-here{
        color:#DB393A;
        cursor: pointer;
    }
    .copy-rights-wrapper{
        display: flex;
        flex-direction: column;
        /* margin-top: 4rem !important; */
        padding-right: 6.1rem !important;
        position: absolute;
        right: 11rem;
        bottom: 2%;

    }
    .copy-rights-wrapper > .copy-rights-content{
        white-space: nowrap;
        font-size: 10px;
        color:#0475A1;
        text-align: right;
    }
    .request-demo-btn{
        display: flex;
        flex-direction: row-reverse;
    }
    .request-demo-btn2{
        display: none;
    }
    .contact-title{
        font-size: 40px;
        font-weight: normal;
        font-family: "bebas-kai";
        color:#464646;
    }
    .contact-details{
        color: #464646;
        font-size: 16px;
        font-weight: bold;
        font-family: "proxima-nova", sans-serif;
        align-self: center;
    }
    .detail-icon{
        width: 70px;
    }
    @media screen and (min-width: 1190px) and (max-width:1350px) {
        .left-bottom-img-wrapper > .left-bottom-bg{
            width:25rem;
        }
        .right-bottom-img-wrapper > .right-top-bg{
            width:30rem;
        }
        .login-title{
            font-size:2rem;
        }
    }
    @media screen and (min-width: 1000px) and (max-width: 1190px){
        .left-bottom-img-wrapper > .left-bottom-bg{
            width: 22rem;
        }
        .right-bottom-img-wrapper > .right-top-bg{
            width: 22rem;
        }
        .right-bottom-img-wrapper > .login-right-bg-img{
            width: 15rem;
        }
        .contact-header{
            padding: 1rem 3rem;
        }
        .copy-rights-wrapper{
            padding-right: 3rem !important;
        }
        .login-title{
            font-size:2rem;
        }
    }
    @media screen and (min-width: 760px) and (max-width: 1000px){
        .left-bottom-img-wrapper > .left-bottom-bg{
            width: 15rem;
        }
        .right-bottom-img-wrapper > .right-top-bg{
            width: 15rem;
        }
        .right-bottom-img-wrapper > .login-right-bg-img{
            width: 10rem;
        }
        .contact-header{
            padding: 1rem 3rem;
        }
        .copy-rights-wrapper{
            padding-right: 3rem !important;
        }
        .login-title{
            font-size:2rem;
        }
        .contact-title{
            font-size: 20px;
        }
        .contact-details{
            font-size: 14px;
        }
        .detail-icon{
            width: 40px;
        }
    }
    @media screen and (min-width: 320px) and (max-width: 760px){
       
        .left-bottom-img-wrapper{
            width: 8rem;
            display: none;
        }
        .right-bottom-img-wrapper > .right-top-bg{
            width: 9rem;
        }
        .right-bottom-img-wrapper > .login-right-bg-img{
            width: 6rem;
        }
        .contact-header{
            padding: 1rem;
        }
        .copy-rights-wrapper{
            padding-right: 1rem !important;
            right:0rem;
        }
        .login-title{
            font-size: 1.3rem;
            margin-bottom: .5rem;
        }
        .request-demo-btn{
            display: none;
        }
        .request-demo-btn2{
            display: flex;
            flex-direction: row-reverse;
        }
        .contact-title{
            font-size: 25px;
            text-align: left !important;
        }
        .contact-details{
            font-size: 12px;
        }
        .detail-icon{
            width: 35px;
        }
    }
</style>