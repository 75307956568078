<template>
  <div id="map"></div>
</template>
<script>
export default {
  props: ["center"],
  data() {
    return {
      routes: [],
      map: "",
    };
  },
  created() {
    var mapsapi = require("google-maps-api")(
      process.env.VUE_APP_GOOGLE_MAPS_API_KEY
    );
    mapsapi().then((maps) => {
      this.initMap(maps);
    });
  },
  watch: {
  },
  methods: {
    initMap(maps) {
      var directionsService = new maps.DirectionsService();
      var directionsRenderer = new maps.DirectionsRenderer();
      var map = new maps.Map(document.getElementById("map"), {
        zoom: 8,
        center: this.center,
      });
      directionsRenderer.setMap(map);
    //   var infoWindow = new google.maps.InfoWindow();
    //   this.calculateAndDisplayRoute(
    //     directionsService,
    //     directionsRenderer,
    //   );
    },
    calculateAndDisplayRoute(
      directionsService,
      directionsRenderer,
      
    ) {
      console.log(this.waypts, directionsService,this.origin,this.destination);
      let request = {
          center:this.center,
            optimizeWaypoints: true,
      };
      directionsService.route(request, function(response, status) {
        if (status == "OK") {
          directionsRenderer.setDirections(response);
        }
      });
    },
  },
};
</script>
<style scoped>
#map {
  min-width: 50px;
  min-height: 500px;
}
</style>
